import { Show, For, Component, createResource } from "solid-js";
import MapGL, { Marker, Control } from "solid-map-gl";
import { createStore, produce } from "solid-js/store";
import { mapPin, xMark } from "solid-heroicons/outline";
import { debounce } from "@solid-primitives/scheduled";
import { Motion, Presence } from "solid-motionone";
import Body from "~/components/Body";
import { SelectBox, TextFieldInput } from "~/components/inputs";
import { Icon } from "solid-heroicons";
import { arrowTopRightOnSquare } from "solid-heroicons/outline";
import { Viewport } from "solid-map-gl";
import { MetaDetails, BaseLoader } from "~/components/utility";
import { formatPhoneNumber, toTitleCase } from "~/utils/helpers";

import "mapbox-gl/dist/mapbox-gl.css";

export default function AuthorizedRetailers() {
  const [details, setDetails] = createStore<{
    center: number[] | null;
    zoom: number;
    search: string | undefined;
    calculatedCenter: number[];
  }>({
    get calculatedCenter() {
      if (this.center !== null) return this.center;
      let lat = 40.787;
      let long = -73.964;
      // ? Hardcoded values to set initial view over NYC at TGs request
      return [long, lat];
    },
    center: null,
    zoom: 10,
    search: undefined,
  });

  const [retailers] = createResource(
    () => [details.calculatedCenter, details.zoom],
    async ([center, zoom]) => {
      const params = new URLSearchParams();
      params.set("lat", (center as number[])[0].toString());
      params.set("lng", (center as number[])[1].toString());
      params.set("radius", convertZoomToMetres(zoom as number));
      const request = await fetch(
        `${import.meta.env.VITE_ROMA_API}/retailers?${params}`
      );
      const response: Retailers = await request.json();
      response.Results = response.Results.map((item) => ({
        ...item,
        Location: [item.Location[1], item.Location[0]],
        Name: toTitleCase(item.Name),
        Street: toTitleCase(item.Street),
        Phone: formatPhoneNumber(item.Phone) as string,
        City: toTitleCase(item.City),
      }));
      return response;
    }
  );
  const [suggestions, { mutate: setSuggestions }] = createResource<
    Suggestion[]
  >(
    () => details.search,
    async (key: string | null) => {
      const params = new URLSearchParams();
      params.set("access_token", import.meta.env.VITE_MAPBOX_ACCESS_TOKEN);
      const request = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${key}.json?${params}`
      );
      const result = await request.json();
      return result.features.map((item: any) => ({
        name: item.place_name,
        location: item.center,
      }));
    },
    // @ts-ignore
    { ssrLoadFrom: "initial", initialValue: [] }
  );

  // ! END DATA FILE

  // * STORES * //

  const [viewport, setViewport] = createStore({
    center: details.calculatedCenter,
    zoom: 10,
  });
  const [draggedView, setDraggedView] = createStore({
    center: viewport.center,
    zoom: viewport.zoom,
  });
  const setDetailsDebounce = debounce((arg: { search?: string }) => {
    setDetails(arg);
  }, 200);

  // * COMPS * //

  const MapPin: Component<{ index: number }> = (props) => {
    return (
      <div class="pb-10">
        <div class="w-10 h-10 bg-roma-blue rounded-full flex justify-center items-center border-2 border-white rotate-45 rounded-br-none">
          <p class="text-white font-bold -rotate-45">{props.index}</p>
        </div>
      </div>
    );
  };

  return (
    <Body>
      <MetaDetails
        title="Authorized Retailers"
        description="Find an authorized retailer near you!"
        keywords="Retailer,Locations"
      />
      <div class=" flex flex-col-reverse md:flex-row mt-8 h-full md:max-h-[80vh] overflow-hidden">
        {/* LEFT SIDE SEARCH */}
        <div class="basis-1/3  max-md:mt-8  overflow-y-auto pl-3 relative">
          <div class="border-b pb-8 md:pr-6 sticky top-0 backdrop-blur-md bg-white/80">
            <h2 class="text-2xl font-bold mb-8">Find an Authorized Retailer</h2>
            <div class="relative flex flex-col gap-4 space-x-1 w-full">
              <TextFieldInput
                name="postalcode"
                icon={mapPin}
                disabled={retailers.loading}
                autocomplete="off"
                rootClass="w-full flex-1"
                onChange={(val) => {
                  if (val !== "") {
                    setDetailsDebounce({ search: val });
                  }
                }}
              />
              <Show
                when={
                  !suggestions.loading &&
                  !retailers.loading &&
                  suggestions().length !== 0
                }
              >
                <button aria-label="Clear" class="absolute top-3 right-4">
                  <Icon
                    path={xMark}
                    class="w-4 h-4 text-roma-medium-grey"
                    onClick={() => setSuggestions([])}
                  />
                </button>
                <Show when={suggestions.loading || retailers.loading}>
                  <div class="absolute right-2 top-2">
                    <BaseLoader width="6" height="6" />
                  </div>
                </Show>
                <Presence exitBeforeEnter>
                  <Show
                    when={
                      suggestions.latest !== null &&
                      suggestions.latest.length !== 0
                    }
                  >
                    <Motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{
                        opacity: 0,
                        y: -10,
                        transition: { duration: 0.3 },
                      }}
                      transition={{ duration: 0.35, easing: "ease-in-out" }}
                      class="absolute left-0 space-y-3 p-3 rounded-b-lg z-10 top-[50%] w-[100%] bg-white  shadow-md"
                      onBlur={() => setSuggestions([])}
                    >
                      <For each={suggestions.latest}>
                        {(item: Suggestion) => (
                          <button
                            class="pointer w-full text-md hover:text-roma-blue  text-left transition-[color] duration-200"
                            onClick={() => {
                              setDetails({
                                center: item.location,
                                zoom: details.zoom || 12,
                              });
                              setViewport("center", item.location);
                              setViewport("zoom", 12);
                              setSuggestions([]);
                            }}
                          >
                            {item.name}
                          </button>
                        )}
                      </For>
                    </Motion.div>
                  </Show>
                </Presence>{" "}
              </Show>
              <div class="flex  justify-between items-center">
                <p class=" text-roma-medium-grey text-sm">
                  {retailers.latest?.Total} locations near you
                </p>
                <SelectBox
                  options={[
                    { label: "5 km", value: 11.5 },
                    { label: "10 km", value: 11 },
                    { label: "20 km", value: 10 },
                    { label: "40 km", value: 9.5 },
                    { label: "60 km", value: 8.5 },
                  ]}
                  defaultValue={details.zoom}
                  onChange={(option) => {
                    setViewport("zoom", option.value as number);
                    setDetails("zoom", option.value as number);
                  }}
                  inlineTitle={
                    viewport.zoom != 3.5 ? "Shops within" : undefined
                  }
                  class="py-1"
                />
              </div>
            </div>
          </div>
          <Show when={!retailers.loading && retailers?.latest?.Total !== 0}>
            <div class="child:pr-2">
              <For each={retailers?.latest?.Results}>
                {(retailer, index) => (
                  <div
                    class="flex items-center py-6 hover:bg-roma-grey duration transition-200 border-b cursor-pointer pl-2"
                    onClick={() => {
                      setViewport(
                        produce((store) => {
                          store.center = retailer.Location;
                          store.zoom = 14;
                        })
                      );
                    }}
                  >
                    <div class="basis-[35px] self-start shrink-0 grow-0 text-roma-blue ml-2">
                      {index() + 1}
                    </div>
                    <div class="grow shrink-0 flex flex-col">
                      <div class="text-lg md:col-span-3">{retailer.Name}</div>
                      <div class="text-sm">
                        <p>{retailer.Street}</p>
                        <p>
                          {retailer.City}, {retailer.Region} {retailer.Postal},{" "}
                          {retailer.Country}
                        </p>
                      </div>
                      <div class="text-sm font-light flex flex-col self-start">
                        <Show when={retailer.Phone}>
                          <a href={`tel:${retailer.Phone}`}>{retailer.Phone}</a>
                        </Show>
                        <Show when={retailer.Website}>
                          <div class="flex items-center">
                            <a
                              class="hover:text-roma-blue"
                              href={retailer.Website}
                              target="_blank"
                            >
                              {retailer.Website}
                            </a>
                            <Icon
                              path={arrowTopRightOnSquare}
                              class="w-3 h-3 ml-2 "
                            />
                          </div>
                        </Show>
                      </div>
                      <a
                        target="_blank"
                        href={`https://www.google.com/maps/dir/?api=1&destination=${retailer.Street.replaceAll(
                          " ",
                          "+"
                        )},+${retailer.City},+${retailer.Region},+${
                          retailer.Country
                        }`}
                        class="self-start grow-0  mt-2 rounded-full border hover:text-white transition-colors duration-100 hover:bg-black border-black px-2 py-2 text-xs whitespace-nowrap"
                      >
                        Get Directions
                      </a>
                    </div>
                  </div>
                )}
              </For>
            </div>
          </Show>
        </div>
        {/* RIGHT SIDE MAP */}
        <div class="basis-2/3">
          <MapGL
            viewport={{ ...viewport }}
            onDragEnd={() => {
              setViewport(draggedView);
            }}
            class=" h-[35vh] md:h-[80vh]"
            options={{
              projection: "mercator",
              dragPan: true,
              doubleClickZoom: true,
              scrollZoom: false,
              interactive: true,
              accessToken: import.meta.env.VITE_MAPBOX_ACCESS_TOKEN,
              style: "mb:light",
              trackResize: true,
            }}
            onViewportChange={(event: Viewport) => {
              setDraggedView({ center: event.center, zoom: event.zoom });
            }}
          >
            <Control type="navigation" position="top-right" />
            <For each={retailers?.latest?.Results}>
              {(retailer, index) => (
                <Marker
                  lngLat={retailer.Location}
                  popup={{
                    focusAfterOpen: true,
                    closeButton: true,
                    closeOnClick: true,
                  }}
                  options={{
                    color: "#0079d1",
                    element: (<MapPin index={index() + 1} />) as HTMLElement,
                  }}
                >
                  {`<div class="p-2"><p class="font-semibold text-base mb-2 border-b border-roma-gray min-w-[200px]">
                    <span class="font-bold text-roma-blue mr-2 ">${
                      index() + 1
                    }</span>
                    ${retailer.Name}
                  </p>
                  <p>${retailer.Street}</p>
                  <p>
                    ${retailer.Region}, ${retailer.Country}
                  </p>
                  <p>${retailer.Postal}</p></div>`}
                </Marker>
              )}
            </For>
          </MapGL>
        </div>
      </div>
    </Body>
  );
}

export type Retailers = {
  Latitude: number;
  Longitude: number;
  Bounds: Array<[number, number]>;
  Radius: number;
  Total: number;
  Results: RetailerModel[];
};
export type RetailerModel = {
  Name: string;
  Street: string;
  Country: string;
  Website: string;
  City: string;
  Phone: string;
  Postal: string;
  Location: [number, number];
  PostalCode: string;
  Region: string;
};
export type Suggestion = {
  name: string;
  location: number[];
};

export const approxMetres = {
  8: 75000,
  8.5: 65000,
  8.75: 55000,
  9: 50000,
  9.5: 40000,
  10: 20000,
  10.5: 15000,
  11: 10000,
  11.5: 5000,
  12: 3500,
  12.5: 2000,
};

const convertZoomToMetres = (num: number): string => {
  switch (num) {
    case 8:
      return "75000";
    case 8.5:
      return "65000";
    case 8.75:
      return "55000";
    case 9:
      return "50000";
    case 9.5:
      return "40000";
    case 10:
      return "20000";
    case 10.5:
      return "15000";
    case 11:
      return "10000";
    case 11.5:
      return "5000";
    case 12:
      return "3500";
    case 12.5:
      return "2000";
    default:
      return "65000";
  }
};
